import React from "react";
import * as styles from "./benefitsgrid.module.css";


export default function BenefitsGrid({ title, items }) {
  return (
    <div className={styles.benefitsGridParent}>
      <h3>{title}</h3>
      <div className={styles.benefitsContainer}>
        {items.map(item => (
          <div className={styles.benefit}>
            <div className={styles.benefitIcon}>{item.icon}</div>
            <div className={styles.benefitTitle}>{item.title}</div>
            <div className={styles.benefitDescription}>
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
