import React from "react";
import { Helmet } from "react-helmet";
import BenefitsGrid from "../../components/BenefitsGrid";
import Layout from "../../components/layout";
import InfoCard from "../../components/InfoCard";
import * as baseStyles from "../index.module.css";
import * as styles from "./pos.module.css";

// images
import inAppPayments from "../../images/in-app-payment.jpg";
import retailer from "../../images/retailer.jpg";
import restaurant from "../../images/restaurant-cafe.jpg";
import salon from "../../images/salon-spa.jpg";
import automotive from "../../images/automotive.jpg";
import b2b from "../../images/b2b.jpg";
import hospitality from "../../images/hotel-hospitality.jpg";
import professionalServices from "../../images/professional-services.jpg";
import homeRepair from "../../images/home-repair.jpg";
import otherBusiness from "../../images/other-business.jpg";

export default function POS() {
  return (
    <Layout>
      <Helmet>
        <title>Point of Sale Payments | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Point of Sale Payments</h1>
          <p>
            Customers crave convenience. They want to pay their way. They want
            instant and secure processing.
            <br />
            Now you can accept virtually every type of payment that comes your
            way!.
          </p>
        </div>

        <div className={styles.whyOnlineParent}>
          <div className={styles.whyOnlineHead}>
            In today’s world, customers crave convenience. They want to pay
            their way. They want instant and secure processing. That’s why you
            need a partner who can support you. MyEZSolutions provides the most
            efficient payment technology the industry has to offer with the most
            competitive processing rates
            <br />
            <br />
            Need help choosing the right payment solution for your online
            business? Contact us for a free, no hassle consultation.
          </div>
          <h4 className={styles.whyOnlineTagline}>
            INNOVATIVE AND AFFORDABLE PAYMENT TECHNOLOGY
          </h4>
          <div className={styles.onlineBenefits}>
            <InfoCard
              imgSrc={inAppPayments}
              imgAlt="In app payments"
              title="Tablet-Based Payment Hardware"
            >
              <p>
                Experience beautiful hardware, software and apps for a truly
                connected experience. Integrate into major POS systems,
                including eHopper, NCR Aloha, Micros and Dinerware. Your
                business can capitalize on modern features like NFC payments,
                EMV compliance, and pay-at-the-table capabilities.
              </p>
              <ul>
                <li>
                  EMV, MagStripe and NFC for Apple Pay, Google Pay and more
                </li>
                <li>
                  View reports, refunds and settle from the smartphone app
                </li>
                <li>Transact anywhere with 3G and WiFi connectivity</li>
                <li>Plug and play with many peripherals</li>
              </ul>
            </InfoCard>
          </div>
          <h4 className={styles.whyOnlineTagline}>
            FULLY SUPPORTED PAYMENT SOLUTIONS FOR EVERY INDUSTRY TYPE
          </h4>
          <div className={styles.onlineBenefits}>
            <InfoCard
              imgSrc={retailer}
              imgAlt="In app payments"
              title="Retailer or In-Store"
            >
              <p>
                When it’s time to pay for purchases, customers expect fast, easy
                and convenient options for their shopping needs.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={restaurant}
              imgAlt="In app payments"
              title="Restaurant or Café"
            >
              <p>
                Restaurants have unique needs for payment processing. We
                understand and want to help make your business a success.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={salon}
              imgAlt="In app payments"
              title="Salon or Spa"
            >
              <p>
                Relax knowing that you won’t be paying high credit card fees.
                Don’t you also deserve to feel stress-free? Now you can.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={automotive}
              imgAlt="In app payments"
              title="Automotive Shop"
            >
              <p>
                Having your shop or dealership accept credit and debit is a
                great convenience for customers. Now, an affordable solution is
                here.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={b2b}
              imgAlt="In app payments"
              title="Business to Business"
            >
              <p>
                Service large opportunities to grow your business. We support
                Level 2 and Level 3 corporate purchasing requirements.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={hospitality}
              imgAlt="In app payments"
              title="Hotel or Hospitality"
            >
              <p>
                Makes it easy to accept digital payments from guests with
                specialized payment solutions for the hospitality industry.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={professionalServices}
              imgAlt="In app payments"
              title="Professional Services"
            >
              <p>
                In your line of work, finding a competitive advantage is
                crucial. Now, you can offer the convenience of credit and debit
                card payments.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={homeRepair}
              imgAlt="In app payments"
              title="Home Repair"
            >
              <p>
                The perfect solution for general contractors, plumbers,
                electricians, landscapers, HVAC, home repair and maintenance.
              </p>
            </InfoCard>
            <InfoCard
              imgSrc={otherBusiness}
              imgAlt="In app payments"
              title="Other Business Types"
            >
              <p>
                Get affordable payment processing solutions and personalized
                service for any business, large or small.
              </p>
            </InfoCard>
          </div>
        </div>

        <BenefitsGrid
          title={"BUT IT DOESN'T STOP THERE"}
          items={[
            {
              icon: (
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"
                  />
                </svg>
              ),
              title: "Next Day Funding",
              description:
                "Get faster access to your cash through our next-day funding service.",
            },
            {
              icon: (
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M13.78 15.3L19.78 21.3L21.89 19.14L15.89 13.14L13.78 15.3M17.5 10.1C17.11 10.1 16.69 10.05 16.36 9.91L4.97 21.25L2.86 19.14L10.27 11.74L8.5 9.96L7.78 10.66L6.33 9.25V12.11L5.63 12.81L2.11 9.25L2.81 8.55H5.62L4.22 7.14L7.78 3.58C8.95 2.41 10.83 2.41 12 3.58L9.89 5.74L11.3 7.14L10.59 7.85L12.38 9.63L14.2 7.75C14.06 7.42 14 7 14 6.63C14 4.66 15.56 3.11 17.5 3.11C18.09 3.11 18.61 3.25 19.08 3.53L16.41 6.2L17.91 7.7L20.58 5.03C20.86 5.5 21 6 21 6.63C21 8.55 19.45 10.1 17.5 10.1Z"
                  />
                </svg>
              ),
              title: "Easy Installation",
              description:
                "Enjoy fast and seamless in-store hardware installation.",
            },
            {
              icon: (
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21M14.8,11V9.5C14.8,8.1 13.4,7 12,7C10.6,7 9.2,8.1 9.2,9.5V11C8.6,11 8,11.6 8,12.2V15.7C8,16.4 8.6,17 9.2,17H14.7C15.4,17 16,16.4 16,15.8V12.3C16,11.6 15.4,11 14.8,11M13.5,11H10.5V9.5C10.5,8.7 11.2,8.2 12,8.2C12.8,8.2 13.5,8.7 13.5,9.5V11Z"
                  />
                </svg>
              ),
              title: "Advanced Security",
              description:
                "Protect your clients’ sensitive data with PCI compliant solutions.                ",
            },
            {
              icon: (
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"
                  />
                </svg>
              ),
              title: "Online Dashboard",
              description:
                "Stay on top of your daily credit and debit transactions. Anywhere, anytime.",
            },
            {
              icon: (
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M19.79,15.41C20.74,13.24 20.74,10.75 19.79,8.59L17.05,9.83C17.65,11.21 17.65,12.78 17.06,14.17L19.79,15.41M15.42,4.21C13.25,3.26 10.76,3.26 8.59,4.21L9.83,6.94C11.22,6.35 12.79,6.35 14.18,6.95L15.42,4.21M4.21,8.58C3.26,10.76 3.26,13.24 4.21,15.42L6.95,14.17C6.35,12.79 6.35,11.21 6.95,9.82L4.21,8.58M8.59,19.79C10.76,20.74 13.25,20.74 15.42,19.78L14.18,17.05C12.8,17.65 11.22,17.65 9.84,17.06L8.59,19.79M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,8A4,4 0 0,0 8,12A4,4 0 0,0 12,16A4,4 0 0,0 16,12A4,4 0 0,0 12,8Z"
                  />
                </svg>
              ),
              title: "24/7 Tech Support",
              description:
                "Get anytime access to reliable, North American tech support.",
            },
          ]}
        />
        <div style={{ marginBottom: "2em" }}>
          <a className={baseStyles.heroCta} href={"/get-quote"}>
            Get Started
          </a>
        </div>
      </div>
    </Layout>
  );
}
